export const apiRoutes = {
  place: {
    detail: 'gplaces/web/v2/detail',
    geocode_reverse: '/wgeo/v2/reverse',
    geocode: 'wgeo/geocode',
  },
  ahafood: {
    store: {
      get_deal: `api/v3/public/food-order/tag`,
      get_store: `api/v3/public/food-order/store`,
      get_category: `/api/v3/public/food-order/category`,
    },
    update_page: (pageId: string) =>
      `/api/v3/private/food-order/page/${pageId}`,
    get_page: `/api/v3/private/food-order/page`,
    get_info: 'api/v3/private/food-order/self',
    fb_login: 'api/v3/public/food-order/social-login',
    acquire_merchant: '/web/v1/public/ahmsites/form/acquire-merchant',
    append_page: (pageId: string) => `/v3/internal/page/facebook/${pageId}`,
  },
};
